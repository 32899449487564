import { Grid2, Typography } from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import number5 from "../../../../assets/lotties/number5.json";

const MerchantCard = ({ merchant, isSelected, handleClick }) => {
  //SECTION - general
  const theme = useTheme();
  const { i18n, t } = useTranslation();

  //SECTION - styles
  const imgStyles = {
    maxWidth: "60px",
    height: "100%",
    objectFit: "contain",
    borderRadius: theme.borderRadius.r5,
  };

  return (
    <Stack
      key={merchant.id}
      onClick={handleClick}
      direction={"row"}
      gap={theme.spacing(1)}
      alignItems={"center"}
      padding={theme.spacing(1)}
      border={`1px ${theme.palette.grey[300]} solid`}
      borderRadius={theme.borderRadius.r15}
      minWidth={{
        xs: "fit-content",
        sm: "fit-content",
      }}
      sx={{
        cursor: "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        overflow: "hidden",
        position: "relative",
        boxShadow: "0px 0px 20px -5px rgba(0,0,0,0.1)",
        ":hover": {
          transform: "scale(1.01)",
        },
        border: isSelected && `2px ${theme.palette.secondary.main} solid`,
      }}
    >
      <Box
        height={"60px"}
        borderRadius={theme.borderRadius.r10}
        overflow={"hidden"}
      >
        <img alt="tabby" style={imgStyles} src={merchant.logo.file} />
      </Box>
      <Stack>
        <Typography fontWeight={"bold"} fontSize={"1.4rem"} noWrap>
          {i18n.languages[0] === "ar" ? merchant.name_ar : merchant.name_en}
        </Typography>
        {merchant.id === 3 ? (
          <Grid2 container flexDirection={"row"}>
            <Typography
              color={theme.palette.grey[600]}
              fontSize={{
                xs: "0.9rem",
                sm: "1rem",
              }}
              noWrap
            >
              {t("wallet.misPayDescription_1")}
            </Typography>
            <Lottie
              style={{ marginInline: theme.spacing(1) }}
              speed={1}
              height={"1.2rem"}
              width={"1.2rem"}
              options={{
                loop: true,
                autoplay: true,
                animationData: number5,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
            <Typography
              color={theme.palette.grey[600]}
              fontSize={{
                xs: "0.9rem",
                sm: "1rem",
              }}
              noWrap
            >
              {t("wallet.misPayDescription_2")}
            </Typography>
          </Grid2>
        ) : (
          <Typography
            color={theme.palette.grey[600]}
            fontSize={{
              xs: "0.9rem",
              sm: "1rem",
            }}
            noWrap
          >
            {merchant.name_en === "Tabby"
              ? t("wallet.tabbyDescription")
              : t("wallet.tamaraDescription")}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default MerchantCard;
