import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TbTablePlus } from "react-icons/tb";
import { LuArrowRightLeft } from "react-icons/lu";
import { useSelector } from "react-redux";
import { CgSpaceBetween } from "react-icons/cg";
import { IoDocumentsOutline } from "react-icons/io5";

const CustomTabs = ({ tabIndex, setTabIndex }) => {
  // SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // SECTION - useSelector
  const canViewWallet = useSelector(
    (state) => state.walletReducer.userCanViewWallet
  );

  // SECTION - tabs
  const tabsData = [
    {
      tabTitle: t("wallet.bnplOrders"),
      tabIcon: <TbTablePlus fontSize={"1.2rem"} />,
    },
    {
      tabTitle: t("wallet.bnplTransactions"),
      tabIcon: <LuArrowRightLeft fontSize={"1.2rem"} />,
    },
    ...(canViewWallet
      ? [
          {
            tabTitle: t("wallet.bnplSettlements"),
            tabIcon: <CgSpaceBetween fontSize={"1.2rem"} />,
          },
          {
            tabTitle: t("wallet.documents"),
            tabIcon: <IoDocumentsOutline fontSize={"1.2rem"} />,
          },
        ]
      : []), // Append the third tab if betaUser is true
  ];

  return (
    <Tabs
      value={tabIndex}
      onChange={(e, newTabIndex) => setTabIndex(newTabIndex)}
      variant="scrollable"
      sx={{
        flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
        ".MuiTabs-scrollButtons": {
          borderBottom: `1px solid ${theme.palette.grey[500]}`,
          flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
        ".MuiButtonBase-root-MuiTab-root.Mui-selected": {
          color: "#fff",
        },
      }}
      indicatorColor="secondary"
      scrollButtons="auto"
      allowScrollButtonsMobile
      textColor="inherit"
      TabScrollButtonProps={{ disabled: false }}
    >
      {tabsData.map((tab, index) => (
        <Tab
          label={tab.tabTitle}
          value={index}
          icon={tab.tabIcon}
          iconPosition="start"
          wrapped="false"
          sx={{
            alignItems: "center",
            marginBottom: 0,
            paddingY: 2,
            paddingX: 1,
            gap: "0.5rem",
            backgroundColor: `${
              tabIndex === index ? theme.palette.secondary.main : "white"
            }`,
            minWidth: 0,
            minHeight: 0,
            color: `${tabIndex === index ? "white" : undefined}`,
            borderTopLeftRadius: theme.borderRadius.r15,
            borderTopRightRadius: theme.borderRadius.r15,
            justifyContent: "center",
          }}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
