import { React, useState, useEffect } from "react";
import {
  Grid2,
  Typography,
  TextField,
  useTheme,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import validatePhone from "../../utils/validation/ValidatePhone";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import ButtonComp from "../../components/common/ButtonComp";
import googlePlay from "../../assets/images/logos/googlePlay.png";
import appStore from "../../assets/images/logos/appStore.png";
import { loginWithPhone } from "../../app/features/auth/auth";
import { useDispatch } from "react-redux";
import moment from "moment";

const LoginWithPhone = () => {
  //SECTION - general
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  //SECTION - useState
  const [phone, setPhone] = useState("");
  const [invalidPhoneText, setInvalidPhoneText] = useState("");
  const [invalidPhoneInform, setInvalidPhoneInform] = useState(false);

  //SECTION - useEffect
  useEffect(() => {
    if (phone !== "") {
      if (!validatePhone(phone)) {
        setInvalidPhoneText(t("login.invalidPhone"));
        setInvalidPhoneInform(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, i18n.language]);

  //SECTION - functions
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^\d٠-٩]/g, "").slice(0, 10); // Remove non-numeric and non-Arabic numeric characters and limit to 10 digits
    setPhone(numericValue);
    if (numericValue.length === 10) {
      if (!validatePhone(numericValue)) {
        setInvalidPhoneText(t("login.invalidPhone"));
        setInvalidPhoneInform(true);
      } else {
        setInvalidPhoneText("");
        setInvalidPhoneInform(false);
      }
    } else {
      setInvalidPhoneText("");
      setInvalidPhoneInform(false);
    }
  };

  const handleSubmit = async () => {
    if (validatePhone(phone)) {
      dispatch(loginWithPhone(phone)).then((payload) => {
        if (payload.type === "auth/loginWithPhone/fulfilled") {
          localStorage.setItem("phone", phone);
          //NOTE - get watchtower token after login
          navigate("/otp");
        }
      });
    } else {
      setInvalidPhoneText(t("login.serverError"));
      setInvalidPhoneInform(true);
    }
  };

  return (
    <Grid2
      container
      justifyContent="center"
      alignItems={{ xs: "", sm: "center", md: "center" }}
      marginTop={isMobileScreen && "10%"}
      height="100vh"
      backgroundColor={{
        xs: "background.default",
        sm: "primary.light",
        md: "primary.light",
      }}
      sx={{ overflow: "hidden" }}
    >
      <Stack
        direction="column"
        bgcolor="background.default"
        width={{ xs: "100%", sm: "70%", semiMd: "60%", md: "40%" }}
        height={{ xs: "60%", sm: "65%", semiMd: "65%", md: "70%" }}
        justifyContent="center"
        alignItems="center"
        borderRadius="0.5rem"
        sx={{
          maxWidth: "800px",
          maxHeight: "750px",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        gap={"2rem"}
      >
        <Typography variant="h3" fontWeight="bold">
          {t("login.header")}
        </Typography>
        <Typography variant="body1">{t("login.phoneEnter")}</Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          placeholder={t("login.phoneFormat")}
          sx={{
            width: "80%",
            maxWidth: "400px",
          }}
          inputProps={{
            sx: {
              direction: i18n.language === "ar" ? "rtl" : "ltr",
            },
          }}
          value={phone}
          onChange={handlePhoneChange}
          helperText={invalidPhoneText}
          error={invalidPhoneInform}
          type="tel"
        />
        <ButtonComp
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          content={t("otp.send")}
          size="large"
          disabled={!validatePhone(phone)}
          sx={{
            width: "80%",
            maxWidth: "400px",
          }}
        />
        <Stack direction={"row"} gap={theme.spacing(2)} py={theme.spacing(1)}>
          <Box
            width={{ xs: "100%", semiSm: "fit-content" }}
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.autobia.autobia"
              )
            }
          >
            <img
              alt="googlePlay"
              style={{
                maxWidth: "125px",
              }}
              src={googlePlay}
            />
          </Box>
          <Box
            width={{ xs: "100%", semiSm: "fit-content" }}
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://apps.apple.com/sa/app/id1661648763")
            }
          >
            <img alt="appStore" style={{ maxWidth: "125px" }} src={appStore} />
          </Box>
        </Stack>
        <Typography sx={{ direction: "ltr" }}>
          {t("login.copyrights", { year: moment().year() })}
        </Typography>
      </Stack>
    </Grid2>
  );
};

export default LoginWithPhone;
