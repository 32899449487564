//SECTION - GENERAL
import React, { useEffect } from "react";
import Snackbar from "../src/components/common/Snackbar";
import "./App.css";
import WrapperNotAuthed from "./components/Template/Wrapper/WrapperNotAuthed";

//SECTION - PAGES
import LoginWithOTP from "./views/Login/LoginWithOTP";
import LoginWithPhone from "./views/Login/LoginWithPhone";
import Home from "./views/Home/Home";
import Cart from "./views/Cart/Cart";
import Payment from "./views/Payment/Payment";
import TermsAndConditions from "./views/TermsAndConditions/TermsAndConditions";
import Wrapper from "./components/Template/Wrapper/Wrapper";
import Orders from "./views/Orders/Orders";
import Offers from "./views/Offers/Offers";
import Wallet from "./views/Wallet/Wallet";
import ErrorPage from "./components/Template/Error/ErrorPage";
import WalletCreateInvoice from "./views/Wallet/WalletCreateInvoice";
import Rewards from "./views/Rewards/Rewards";
import OrderTracking from "./views/OrderTracking/OrderTracking";

//SECTION - ROUTES
import { Route, Routes, Navigate } from "react-router-dom";
import AuthProtected from "./routes/AuthProtected";
import LoginProtected from "./routes/LoginProtected";
import OTPProtected from "./routes/OTPProtected";
import { useLocation } from "react-router-dom";

//SECTION - PROVIDERS
import { I18nextProvider, useTranslation } from "react-i18next";
import theme from "./utils/materialUI/Theme";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { currentlySelectedOrder } from "./app/features/order/order";
import { currentlySelectedOffer } from "./app/features/offer/offer";
import Smartlook, { useSmartlook } from "./utils/api/smartlook";
import {
  isAuthenticated,
  validateWatchtowerToken,
} from "./utils/api/Authentication";
import { jwtDecode } from "jwt-decode";
import { checkBetaUser, updateUserLang } from "./app/features/auth/auth";
import IsEmpty from "./utils/validation/IsEmpty";
import IndexWallet from "./views/Wallet/IndexWallet";
import { initSentry, setUserContext } from "./utils/api/sentry";

initSentry();

const App = () => {
  //SECTION - Translation realted
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const { setUserAttributes, alreadySetUserAttributes } = useSmartlook();
  const dispatch = useDispatch();

  //SECTION - useSelector
  const selectedOrder = useSelector(currentlySelectedOrder);
  const selectedOffer = useSelector(currentlySelectedOffer);
  const betaUser = useSelector((state) => state.authReducer.betaUser);

  //SECTION - Title setup
  const getPageTitle = (url) => {
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    switch (lastPart) {
      case "home":
        return "Home - Autobia: Your Source for Quality Car Parts";
      case "orders":
        return selectedOrder
          ? `Order Details - Order ID: ${selectedOrder.id} | Status:  ${selectedOrder.status}`
          : "My Orders - Track and Manage Your Autobia Parts Purchases";
      case "offers":
        return selectedOffer
          ? `${
              i18next.language === "ar"
                ? selectedOffer.message_ar
                : selectedOffer.message_en
            } - Offer ID: ${selectedOffer.id}`
          : "Special Offers - Exclusive Deals and Discounts on Car Parts";
      case "cart":
        return "My Cart - Review and Manage Your Autobia Parts Selection";
      case "FAQ":
        return "FAQ - Get Answers to Frequently Asked Questions";
      case "payment":
        return "Payment Methods - Convenient & Flexible Payment Options";
      case "terms":
        return "Policy - Learn About Autobia's Terms and Conditions";
      default:
        return "Autobia";
    }
  };

  const location = useLocation();
  const currentUrl = location.pathname;
  const pageTitle = getPageTitle(currentUrl);

  useEffect(() => {
    if (
      isAuthenticated() &&
      !alreadySetUserAttributes &&
      validateWatchtowerToken() &&
      !IsEmpty(betaUser)
    ) {
      const user = jwtDecode(localStorage.getItem("watchTowerAccessToken"));
      //update smartlook user attributes
      setUserAttributes(user.user_id, {
        name: `${user.user.first_name} ${user.user.last_name}`,
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        language: localStorage.getItem("lng"),
        isBetaUser: betaUser,
      });
      //update sentry user attributes
      setUserContext(user.user_id, {
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        isBetaUser: betaUser,
        lang: localStorage.getItem("lng"),
      });
      //update user language in DB
      if (localStorage.getItem("lng")) {
        dispatch(
          updateUserLang({ lang: localStorage.getItem("lng").toUpperCase() })
        );
      } else {
        localStorage.setItem("lng", i18n.language);
        dispatch(updateUserLang({ lang: i18n.language.toUpperCase() }));
      }
    }
  }, [
    setUserAttributes,
    betaUser,
    alreadySetUserAttributes,
    dispatch,
    i18n.language,
  ]);

  useEffect(() => {
    //check beta user
    if (localStorage.getItem("watchTowerAccessToken")) {
      dispatch(checkBetaUser());
    }
  }, [dispatch]);

  return (
    <div className="root2">
      <Smartlook />
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar />
          <Routes>
            <Route element={<LoginProtected />}>
              <Route
                path="/login"
                element={<WrapperNotAuthed component={<LoginWithPhone />} />}
              />
            </Route>
            <Route element={<OTPProtected />}>
              <Route
                path="/otp"
                element={<WrapperNotAuthed component={<LoginWithOTP />} />}
              />
            </Route>

            <Route element={<AuthProtected />}>
              <Route path="/home" element={<Wrapper component={<Home />} />} />
              <Route path="/cart" element={<Wrapper component={<Cart />} />} />
              <Route
                path="/orders"
                element={<Wrapper component={<Orders />} />}
              />
              <Route
                path="/offers"
                element={<Wrapper component={<Offers />} />}
              />
              <Route
                path="/payment"
                element={<Wrapper component={<Payment />} />}
              />
              <Route
                path="/rewards"
                element={<Wrapper component={<Rewards />} />}
              />
              {/* <Route path="/FAQ" element={<Wrapper component={<FAQ />} />} /> */}
              <Route
                path="/terms"
                element={<Wrapper component={<TermsAndConditions />} />}
              />
              <Route
                path="/installment-services"
                element={<Wrapper component={<IndexWallet />} />}
              />
              <Route
                path="/wallet"
                element={<Wrapper component={<Wallet />} />}
              />
              <Route
                path="/wallet/createInvoice/:merchantName?"
                element={<Wrapper component={<WalletCreateInvoice />} />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route
              path="/error/:code"
              element={<Wrapper component={<ErrorPage />} />}
            />
            <Route
              path="/order-tracking/:id"
              element={<Wrapper component={<OrderTracking />} />}
            />
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </div>
  );
};
export default App;
