import React from "react";
import StyledInput from "../../../components/common/StyledInput";
import StyledSelect from "../../../components/common/StyledSelect";
import StyledDatePicker from "../../../components/common/StyledDatePicker";
import ButtonComp from "../../../components/common/ButtonComp";
import { Grid } from "@mui/material";

const FilterSection = ({ currentConfig }) => {
  return (
    <Grid container paddingX={"1rem"} spacing={"1rem"}>
      {currentConfig.filterComponents.map((component, index) => {
        const {
          type,
          props,
          gridProps = { xs: 12, md: 3, lg: 2.4 }, // Default values for gridProps
        } = component;

        let Component;
        switch (type) {
          case "input":
            Component = StyledInput;
            break;
          case "select":
            Component = StyledSelect;
            break;
          case "date":
            Component = StyledDatePicker;
            break;
          case "button":
            Component = ButtonComp;
            break;
          // Add more component types as needed
          default:
            return null;
        }

        return (
          <Grid
            item
            container
            {...gridProps} // Use gridProps for dynamic sizes
            key={index}
            alignItems={"flex-end"}
            justifyContent={type === "button" ? "flex-end" : "auto"}
          >
            <Component {...props} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FilterSection;
