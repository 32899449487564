import { useTheme } from "@emotion/react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid2,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle, AiOutlineClose } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import Backdrop from "@mui/material/Backdrop";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createRefund,
  getClientWallet,
} from "../../../../app/features/wallet/wallet";
import { notifyWithSuccess } from "../../../../app/features/error/error";
import { ccyFormat } from "../../../../utils/Helpers/general";
import Lottie from "react-lottie";
import warningLottie from "../../../../assets/lotties/warning.json";

//SECTION - styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  maxHeight: "90vh",
  overflowY: "auto",
};

const CreateRefundModal = ({ open, handleClose, fetchOrders, row }) => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [remainingBalance, setRemainingBalance] = useState(0);

  //SECTION - useEffect
  useEffect(() => {
    const calculateRemainingAvailableBalance = () => {
      let remainingBalance = row?.price || 0;
      if (row?.refunds?.length > 0) {
        row.refunds.forEach((refund) => {
          remainingBalance -= refund.amount;
        });
      }
      setRemainingBalance(remainingBalance.toFixed(2));
    };
    if (open) {
      calculateRemainingAvailableBalance();
    }
  }, [open, row]);

  //SECTION - useSelector
  const createRefundState = useSelector(
    (state) => state.walletReducer.createRefundState
  );

  //SECTION - Yup Validation Schema
  const FORM_VALIDATION = Yup.object().shape({
    amount: Yup.number()
      .typeError(
        t("formValidation.number", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .required(
        t("formValidation.required", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .positive(
        t("formValidation.positiveNumber", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .max(
        remainingBalance,
        `${t("wallet.yupMoreThanRemainingBalance", {
          amount: ccyFormat(remainingBalance),
        })} ${t("wallet.riyal")}`
      ),
    reason: Yup.string().required(
      t("formValidation.required", {
        field: i18n.language === "ar" ? "السبب" : "Reason",
      })
    ),
  });

  //SECTION - functions
  const resetFields = () => {
    formik.values.amount = "";
    formik.values.reason = "";
    formik.errors.amount = null;
    formik.errors.reason = null;
    setRemainingBalance(0);
  };

  const handleSubmitForm = (values) => {
    dispatch(
      createRefund({
        payment_gateway_session_id: row?.id,
        amount: parseFloat(values.amount),
        reason: values.reason,
        platform: "Web Store",
      })
    ).then((payload) => {
      if (payload.type === "wallet/createRefund/fulfilled") {
        dispatch(
          notifyWithSuccess({
            message: t("wallet.refundCreatedSuccessfully"),
            type: "success",
          })
        );
        fetchOrders();
        dispatch(getClientWallet());
        resetFields();
        handleClose();
      }
    });
  };

  //SECTION - Formik
  const formik = useFormik({
    initialValues: { amount: "", reason: "" },
    enableReinitialize: true,
    validationSchema: FORM_VALIDATION,
    onSubmit: handleSubmitForm,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        resetFields();
        handleClose();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack gap={theme.spacing(2)}>
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <IconButton sx={{ visibility: "hidden" }}>
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={"1.8rem"}
              >
                {t("wallet.createRefund")}
              </Typography>
              <IconButton
                onClick={() => {
                  resetFields();
                  handleClose();
                }}
              >
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
            </Stack>

            <Grid2
              container
              direction="column"
              gap={1}
              sx={{
                backgroundColor: theme.palette.gray.g75,
                borderRadius: theme.borderRadius.r15,
                paddingY: "1rem",
                paddingX: "1rem",
                minHeight: 100,
              }}
            >
              <Grid2
                container
                direction={{ md: "row" }}
                justifyContent="flex-start"
                spacing={1}
                size={{ xs: 12 }}
              >
                <Grid2
                  container
                  direction={{ md: "row" }}
                  spacing={1}
                  size={{ xs: 12, md: 6 }}
                >
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.transaction_id")}
                  </Typography>

                  <Typography fontWeight={theme.typography.bold}>
                    {row?.orderID}
                  </Typography>
                </Grid2>
                <Grid2
                  container
                  spacing={1}
                  direction={{ md: "row" }}
                  size={{ xs: 12, md: 6 }}
                >
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.client_Name")}
                  </Typography>
                  <Typography fontWeight={theme.typography.bold}>
                    {row?.clientName}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2
                container
                direction={{ md: "row" }}
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid2 spacing={1} container direction={{ md: "row" }}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.totalAmount")}
                  </Typography>

                  <Typography fontWeight={theme.typography.bold}>
                    {row?.price} {t("wallet.riyal")}
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            {row?.payment_gateway_type?.id === 5 && (
              <Box>
                <Alert
                  icon={false}
                  severity="warning"
                  sx={{ borderRadius: "10px" }}
                >
                  <Grid2 container flexDirection={"row"}>
                    <Lottie
                      style={{ marginInline: theme.spacing(1) }}
                      speed={1}
                      height={"1.8rem"}
                      width={"1.8rem"}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: warningLottie,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                    />
                    <AlertTitle
                      sx={{ marginInline: theme.spacing(1) }}
                      fontSize={20}
                    >
                      {t("wallet.onlyOneRefundAllowed")}
                    </AlertTitle>
                  </Grid2>
                </Alert>
              </Box>
            )}
            {row?.refunds?.length !== 0 && (
              <>
                <Divider textAlign="left">
                  <Typography
                    color="grey"
                    fontWeight={theme.typography.bold}
                    fontSize={theme.spacing(2.1)}
                  >
                    {t("wallet.refundedListTitle")}
                  </Typography>
                </Divider>
                <Grid2
                  container
                  direction="column"
                  gap={1}
                  sx={{
                    backgroundColor: theme.palette.gray.g75,
                    borderRadius: theme.borderRadius.r15,
                    paddingY: "1rem",
                    paddingX: "1rem",
                    minHeight: 50,
                  }}
                >
                  {row &&
                    row?.refunds?.map((refund) => (
                      <Grid2
                        container
                        direction={{ md: "row" }}
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid2
                          container
                          item
                          justifyContent="flex-start"
                          md={2}
                        >
                          <NumericFormat
                            value={refund.amount}
                            thousandSeparator=","
                            displayType="text"
                            renderText={(value) => (
                              <Typography
                                color="secondary"
                                fontWeight={theme.typography.bold}
                              >
                                {` ${value} ${t("currency.SAR")}`}
                              </Typography>
                            )}
                          />
                        </Grid2>
                        <Grid2 container item justifyContent="flex-start" md>
                          <Typography fontWeight={theme.typography.bold}>
                            {`${t("wallet.prevRefundedAmountOn")} ${moment(
                              refund.created_at
                            ).format("DD/MM/YYYY")}`}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    ))}
                </Grid2>
              </>
            )}
            {(!(row?.payment_gateway_type?.id === 5) ||
              (row?.payment_gateway_type?.id === 5 &&
                row?.refunds?.length === 0)) && (
              <form onSubmit={formik.handleSubmit}>
                <Stack gap={theme.spacing(2)}>
                  <Grid2
                    container
                    alignItems={{
                      xs: "flex-start",
                      sm: "center",
                    }}
                    flexDirection={{
                      xs: "column",
                      sm: "row",
                    }}
                  >
                    <Grid2
                      size={{ xs: 12, sm: 4 }}
                      textAlign={i18n.dir() === "ltr" ? "left" : "right"}
                    >
                      <Typography fontWeight={theme.typography.bold}>
                        {`${t("wallet.totalPrice")} (VAT 15%)`}
                      </Typography>
                    </Grid2>
                    <Grid2
                      size={{ xs: 12, sm: 8 }}
                      container
                      sx={{ width: "100%" }}
                    >
                      <TextField
                        color="secondary"
                        fullWidth
                        size="small"
                        name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={t("wallet.addRefundPlaceholder")}
                        error={
                          formik.touched.amount && Boolean(formik.errors.amount)
                        }
                        helperText={
                          formik.touched.amount && formik.errors.amount
                        }
                        slotProps={{
                          input: {
                            sx: {
                              borderRadius: "10px 10px 0px 0px",
                              overflow: "hidden",
                              height: "40px",
                              backgroundColor: theme.palette.white,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  color="secondary"
                                  fontWeight={theme.typography.bold}
                                >
                                  {t("wallet.riyal")}
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                          formHelperText: {
                            sx: {
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            },
                          },
                        }}
                      />
                      <Stack
                        gap={theme.spacing(1)}
                        textAlign={
                          i18n.languages[0] === "ar" ? "right" : "left"
                        }
                        width={"100%"}
                      >
                        <Stack
                          bgcolor={theme.palette.secondary.light}
                          direction={{
                            xs: "column",
                            sm: "row",
                          }}
                          justifyContent={"space-between"}
                          alignItems={{
                            xs: "flex-start",
                            sm: "center",
                          }}
                          paddingX={theme.spacing(1)}
                          paddingY={theme.spacing(0.5)}
                          gap={{
                            xs: theme.spacing(2),
                          }}
                          borderRadius={"0px 0px 10px 10px"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={theme.spacing(1)}
                          >
                            <AiFillExclamationCircle
                              fontSize={{
                                xs: "2rem",
                              }}
                            />

                            <NumericFormat
                              value={remainingBalance}
                              thousandSeparator=","
                              displayType="text"
                              renderText={(value) => (
                                <Typography
                                  fontSize={{
                                    xs: "0.8rem",
                                    sm: "0.9rem",
                                  }}
                                >
                                  {`${t(
                                    "wallet.maxRefundAmountIs"
                                  )} ${value} ${t("currency.SAR")}`}
                                </Typography>
                              )}
                            />
                            <Typography
                              fontSize={{
                                xs: "0.8rem",
                                sm: "0.9rem",
                              }}
                            >
                              {`${t("wallet.refundFeesPercentage")} ${ccyFormat(
                                row?.refundRate * 100
                              )}%`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid2>
                  </Grid2>
                  <Grid2
                    container
                    alignItems={{
                      xs: "flex-start",
                      sm: "center",
                    }}
                    flexDirection={{
                      xs: "column",
                      sm: "row",
                    }}
                  >
                    <Grid2
                      size={{ xs: 12, sm: 4 }}
                      textAlign={i18n.dir() === "ltr" ? "left" : "right"}
                    >
                      <Typography fontWeight={theme.typography.bold}>
                        {t("wallet.reason")}
                      </Typography>
                    </Grid2>
                    <Grid2
                      size={{ xs: 12, sm: 8 }}
                      container
                      gap={theme.spacing(1)}
                      sx={{ width: "100%" }}
                    >
                      <TextField
                        color="secondary"
                        fullWidth
                        size="small"
                        name="reason"
                        multiline
                        rows={4}
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={t("wallet.addReasonPlaceholder")}
                        error={
                          formik.touched.reason && Boolean(formik.errors.reason)
                        }
                        helperText={
                          formik.touched.reason && formik.errors.reason
                        }
                        slotProps={{
                          input: {
                            sx: {
                              borderRadius: "10px",
                              overflow: "hidden",
                              backgroundColor: theme.palette.white,
                            },
                          },
                          formHelperText: {
                            sx: {
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            },
                          },
                        }}
                      />
                    </Grid2>
                  </Grid2>
                  <Box mt={theme.spacing(2)}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={
                        formik.values.amount === "" ||
                        formik.values.reason === "" ||
                        Object.keys(formik.errors)?.length > 0 ||
                        createRefundState === "loading"
                      }
                      onClick={formik.handleSubmit}
                    >
                      {createRefundState === "loading" ? (
                        <CircularProgress size={25} color="white" />
                      ) : (
                        <Typography>{t("wallet.createRefund")}</Typography>
                      )}
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateRefundModal;
